@import './colors.scss';

$BORDER_RADIUS: 10px;
$FORM_BORDER_RADIUS: 10px;
// $BOX_SHADOW: 0px 0px 4px 0px $WHITE;
// $BOX_SHADOW2: 0px 16px 20px 0px $GRAY_SHADE8;
// $CUSTOM_RADIUS: 20px;
// $ROUND_BORDER_RADIUS: 50%;
// $drawerWidth: 240px;

// font families
$MONTSERRAT: 'Montserrat', sans-serif;
$POPPINS: 'Poppins', sans-serif;
$RIGHTEOUS: 'AllRoundGothicW01-Medium';
$ROUNDGOTHIC_BOLD: 'AllRoundGothicW01-Bold';
$ROUNDGOTHIC_MEDIUM: 'AllRoundGothicW01-Medium';
$ROUNDGOTHIC_BOOK: 'AllRoundGothicW01-Book';

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}

.frmLeftIcons1 {
  position: absolute;
  top: 27%;
  left: 4%;
}

.cursor-pointer {
  cursor: pointer;
}

.highlight {
  color: #fc8434 !important;
  font-weight: 600 !important;
}

.SingleLinkSize svg {
  color: black !important;
  fill: black !important;
  stroke: black !important;
  width: 22px;
  height: 22px;
}

.SingleLinkSize.highlight-blue svg {
  fill: #2b6fed !important;
  stroke: #2b6fed !important;
  color: #2b6fed !important;
}

.highlight-blue {
  color: #2b6fed !important;
}

.MuiSelect-select:focus {
  border-radius: 25px !important;
}

.MuiAccordion-root:before {
  background-color: transparent !important;
}
