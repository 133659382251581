.Announcements_Table_Div {
    border-radius: 8px;
    background: #fff;
    width: 100%;
    height: 652px;
    overflow-y: auto;
    box-shadow: 4px 0px 14px 0px #e9e9e9;
    padding: 20px 0 0;
}

.Announcements_Table_Div .MuiDataGrid-root {
    border: none !important;
}

.Announcements_Table_Div .MuiDataGrid-root .MuiDataGrid-iconSeparator {
    color: inherit;
    display: none;
}

.Table_Heading {
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.Search_Div {
    width: 249px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    background: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 20px;
    margin-right: 15px;
}

.Announcements_Search {
    width: 12px;
    height: 12px;
    margin-right: 8px;
    opacity: 1;
}

.Announcements_Search_Text {
    color: #0e0d0d;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none;
    border: none;
}

.Announcements_Table_Heading {
    color: #181d25;
    font-family: inherit;
    font-size: 22px;
    font-style: normal;
    font-weight: bolder !important;
    line-height: normal;
}

.Announcements_Select_Div {
    width: 96px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #eaeaea;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Announcements_Table_Right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.Announcements_Select_Tag {
    color: #000;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.7;
    appearance: none;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    background: #fff;
    height: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 24px 0 12px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 7px center;
    background-size: 1em;
}

.Announcements_Table {
    padding: 0px 30px;
    width: 100%;
    height: 85% !important;
}

.Announcements_Table .MuiDataGrid-cell {
    -webkit-tap-highlight-color: transparent;
    text-align: left !important;
    padding: 0 0 0 16px !important;
}

.Announcements_Table .MuiDataGrid-cell:focus,
.Announcements_Table .MuiDataGrid-columnHeader:focus {
    outline: none !important;
}

.Announcements_Table .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer {
    flex-direction: row !important;
}

.Announcements_Details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.Description_Image_Container {
    margin-bottom: 25px;
}

.Description_Image {
    width: 100%;
    height: 300px;
}

.withdraw_Announcement {
    background-color: rgb(252, 132, 52);
    outline: none;
    border: none;
    border-radius: 8px;
    display: flex;
    padding: 0 2%;
    height: 40px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    text-align: center;
    color: white;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
}

.Announcements_Description {
    border-radius: 8px;
    background: #fcfcfc;
    height: auto;
    padding: 25px;
}

.Description_Heading {
    color: #272b30;
    font-family: inherit;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Building_Rules {
    outline: none;
    border: none;
    width: 112px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 20px;
    background: rgba(43, 111, 237, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2b6fed !important;
    font-size: 12px;
    font-weight: 400;
    font-family: inherit;
}

.Description_Name_div {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    color: #272b30;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.16px;
}

.Disc {
    margin-left: 18px;
    width: 16px;
    height: 16px;
}

.Description_Text {
    color: #1a1d1f;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.Description_Details_Div {
    margin-top: 27px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.Description_Details {
    margin: 10px 35px 10px 0;
    display: flex;
}

.Description_Details_Img_Div {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: flex-start;
}

.Description_Details_Img {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    opacity: 0.7;
    margin-right: 10px;
}

.Description_Details_Heading {
    color: #a9a8a9;
    font-family: inherit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
}

.Description_Details_Text {
    color: #181d25;
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Description_Withdraw_Text {
    margin-bottom: 0 !important;
}

.Description_Withdraw {
    padding: 0 20%;
}

.Announcements_Heading_Txt {
    margin-bottom: 0;
}

@media (max-width: 650px) {
    .Announcements_Details {
        display: block;
    }

    .withdraw_Announcement {
        margin: 10px 0;
        font-size: 12px;
    }

    .Building_Rules {
        margin: 10px 0;
    }

    .Description_Image {
        height: auto;
    }
}

@media (max-width: 485px) {
    .Search_Div {
        margin: 10px 15px 10px 0;
        width: 100%;
    }

    .Table_Heading {
        padding: 0 3%;
    }

    .ManageFAQ_Tag {
        font-size: 10vw;
    }

    .Description_Heading {
        font-size: 14px;
    }
}

.cell-one {
    max-width: 20px;
    /* overflow: auto; */
    /* text-align: center !important; */
    word-wrap: nowrap;

}

.cell-two {
    max-width: 145px;
    /* overflow: auto; */
    /* text-align: center !important; */
    word-wrap: nowrap;

}

.cell-three {
    max-width: 85px;
    /* overflow: auto; */
    /* text-align: center !important; */
    word-wrap: nowrap;

}

.cell-four {
    max-width: 85px;
    /* overflow: auto; */
    /* text-align: center !important; */
    word-wrap: nowrap;

}

.top-bar-filter .filter-filter .filter_select_color_announcement {
    color: #7D7C7C !important;
}

.top-bar-filter .filter-filter .filter_select_color_announcement_sort_by {
    color: #191717 !important;
    font-weight: bold
}
