/* Login and Forgot password */
.container {
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-x: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.complex_page_slider {
  position: relative;
}
.complex_page_slider .slick-list {
  overflow: hidden;
}
.complex_page_slider .slick-arrow {
  display: none !important;
}
.complex_page_slider .slick-track {
  display: flex;
  gap: 15px;
}
.table_search .MuiOutlinedInput-input {
  padding: 10px 10px;
  width: 275px;
}
.pos_relative {
  position: relative;
}
.form_select_placeholder {
  position: absolute;
  z-index: 99999;
  top: 27.5px;
  left: 7.5px;
  color: #848484 !important;
}
.circle_border {
  border-radius: 8px !important;
}
.estate_select_color {
  color: #848484 !important;
}
.estate_select_color_building {
  color: #bebebe !important;
}
.estate_select_color_building_value {
  color: #212121 !important;
}
.estate_select::before {
  display: none;
}
.active_switch {
  background: #2b6fed33;
}
.active_switch_icon {
  color: rgb(43, 111, 237);
}
#PaginationModule .MuiPaginationItem-outlined {
  color: rgba(0, 0, 0, 0.23);
}
#PaginationModule .MuiPaginationItem-outlined:hover {
  color: #2b6fed;
  border: 1px solid #2b6fed;
}
#PaginationModule .MuiPaginationItem-outlined:hover svg {
  fill: #2b6fed;
}
#PaginationModule .MuiPaginationItem-page.Mui-selected {
  background-color: rgb(252, 132, 52) !important;
  color: #fff;
  border-color: rgb(252, 132, 52) !important;
}
.table_search .MuiIconButton-label {
  margin-left: 10px;
}
.table_search .MuiSvgIcon-root {
  fill: #555 !important;
}
.table_detail_search .MuiSvgIcon-root {
  fill: #555 !important;
}
.table_search .MuiOutlinedInput-input::placeholder {
  color: #555 !important;
  opacity: 1;
}
.table_detail_search .MuiOutlinedInput-input::placeholder {
  color: #000 !important;
}
.table_detail_search .MuiOutlinedInput-input {
  padding: 10px 0px;
  margin-top: -3px;
  width: 225px;
}
.table_detail_search .MuiIconButton-label {
  margin-left: 0px;
  margin-top: -3px;
}
.filter_controller .MuiSvgIcon-root {
  margin-left: -1em;
  height: 17.5px;
  color: rgb(100, 100, 100) !important;
}
.filter_controller .MuiSelect-outlined.MuiSelect-outlined {
  border-radius: 10px;
  background: #fff;
  padding: 10.5px 14px !important;
  font-size: 14px;
}
.filter_select_color .MuiSelect-outlined.MuiSelect-outlined {
  color: #848484;
}
.filter_controller .MuiInputLabel-outlined {
  margin-top: -8px;
  color: #181d25;
  font-size: 14px;
}
.field_placeholder .MuiInputBase-root .MuiInputBase-input::placeholder {
  color: #848484 !important;
  opacity: 1;
}
.field_placeholder .MuiInputBase-root::before {
  display: none !important;
}
.field_placeholder .MuiInputBase-root::after {
  display: none !important;
}
.field_placeholder .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 15px;
}
.delete_reason_field {
  width: 100%;
  border: 1px solid #ececec !important;
  border-radius: 8px;
  overflow: hidden;
  font-size: 14px;
}
.text_capital_select,
.text_capital_select option {
  text-transform: capitalize;
}
.delete_reason_field .MuiInputLabel-outlined {
  line-height: 1.5;
}
.dashboardHeader_lang {
  color: #000 !important;
}
.dashboardHeader_lang.active {
  color: rgb(252, 132, 52) !important;
}
.logo_box {
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.middle_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}
.middleinner_box {
  width: 360.75px;
  height: 353.8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.middlebox_end {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login_button2 {
  height: 56px;
  background-color: #2b6fed;
  color: #ffffff;
  border-radius: 10px;
  font-size: 16px;
  line-height: 20px;
}
.right_image {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100vh;
}
.lock-icon-image {
  filter: invert(56%) sepia(64%) saturate(3015%) hue-rotate(125deg) brightness(96%) contrast(106%);
}
/* Login and Forgot password */
/* Dashboard */
.dashboard_container {
  width: 100%;

  overflow-x: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.dashboard_middle {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 2px;
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: 100%;
}
.dashboard_grid {
  max-width: 100%;
  display: flex;
  flex: 1;
  overflow-x: hidden;
  overflow-y: hidden;
}
.dashboard_feed {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 2px;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: skyblue;
}
.dashboard_card {
  background-color: white;
  /*box-shadow: 4px 0px 14px #e9e9e9;*/
  border-radius: 8px;
}
.dashboard_header {
  display: flex;
  gap: 20px;
  height: 100px;
  justify-content: center;
  background-color: #ffffff;
}
.ListSpacing {
  margin-bottom: 10px;
}

.compempemail input::placeholder {
  color: #0a0a0a !important;
}
.sidebartext {
  font-weight: bold !important;
}
.CESingleLinkSize {
  fill: #2b6fed !important;
  stroke: #2b6fed !important;
  color: #2b6fed !important;
}
.blackCESingleLinkSize {
  fill: #000000 !important;
  stroke: #000000 !important;
  color: #000000 !important;
}
/* Dashboard */
.customInputStyle {
  width: 60px !important;
  height: 40px;
  margin-right: 36px;
  border: none;
  font-weight: bold;
  border-bottom: 2px solid #ccc;
  font-size: 20px;
  text-align: center;
}
.selected {
  color: #fc8434 !important;
}

.real_estate_popover .MuiPaper-elevation8 {
  box-shadow: 0px 0px 6px rgba(209, 209, 209, 0.05) !important;
  width: 160px;
  left: 60px;
  border-radius: 2.5px;
  padding: 10px 15px !important;
}
.real_estate_popover .MuiPopover-paper {
  margin-top: 2px;
  margin-left: 22.5px;
  overflow: unset;
}
.real_estate_popover .MuiPopover-paper::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -9px;
  left: 50%;
  margin-left: 17px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent rgb(255, 255, 255) transparent;
}

.real_estate_popover .MuiPaper-elevation8 {
  box-shadow: 0px 0px 6px rgba(209, 209, 209, 0.05) !important;
  width: 160px;
  left: 60px;
  border-radius: 2.5px;
  padding: 10px 15px !important;
}
.real_estate_popover .MuiPopover-paper {
  margin-top: 2px;
  margin-left: 22.5px;
  overflow: unset;
}
.real_estate_popover .MuiPopover-paper::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -9px;
  left: 50%;
  margin-left: 17px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent rgb(255, 255, 255) transparent;
}

.top-bar-filter {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
.top-bar-filter .filter-filter {
  display: flex;
  align-items: center;
}
.top-bar-filter .filter-filter .MuiInput-underline:before {
  border: 0;
  content: "";
  position: "unset";
}
.top-bar-filter .filter-filter .MuiInput-underline:after {
  border: 0;
  content: "";
  position: unset;
}
.top-bar-filter .filter-filter .select-input {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 7px 7px 7px 12px;
  width: auto;
  min-width: 170px;
  text-transform: capitalize;
  color: #212121 !important;
}
.top-bar-filter .filter-filter .filter_select_color {
  color: rgba(0, 0, 0, 0.8) !important;
}

.top-bar-filter .filter-filter .input {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 7px;
  width: 180px;
}
.top-bar-filter .filter-filter .select-input .MuiSelect-root {
  background: #ffffff;
}
.top-bar-filter .filter-filter .select-input:before {
  border: 0;
}
.top-bar-filter .filter-filter .select-input:after {
  border: 0;
}

.top-bar-filter .filter-filter .MuiInput-underline:hover:not(.Mui-disabled)::before {
  border: unset !important;
}

.filter-filter .search-btn-estate,
.filter-filter .search-btn-estate:hover {
  background: #2b6fed;
  color: white;
  padding: 10px 16px 10px 10px;
  border-radius: 8px;
  font-weight: 600;
}

.filter-filter .clear-filter, 
.filter-filter .clear-filter:hover {
  background-color: #dbdee5;
  color: #000;
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  font-weight: 600;
}

.image-preview {
  flex-wrap: wrap;
}

.image-preview div {
  margin-bottom: 15px;
}

.image-preview div img {
  border-radius: 12px;
}
.totalArea .MuiInputBase-input::placeholder {
  opacity: 0.5 !important;
}
