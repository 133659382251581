.yearTab .MuiInput-underline::before {
  border-bottom: none !important;
}

.yearSelection .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none !important;
}

.yearSelection .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none !important;
}

.AccordinoList .MuiPaper-elevation1 {
  box-shadow: none !important;
}
.AccordinoList .MuiPaper-root {
  background-color: transparent !important;
}
.AccordinoList .MuiAccordion-root {
  position: unset !important;
}

.AccordinoList .MuiButtonBase-root {
  display: flex;
  padding: 0 15px;
  color: black;
}
.AccordinoList .MuiTypography-body1 {
  color: black;
}

/* .AccordinoList .MuiTypography-body1 :hover{
    cursor: pointer;
} */
.SingleLink {
  display: flex;
  padding: 0 15px;
  gap: 15px;
  margin: 30px 0 30px 0;
  align-items: center;
}
.SingleLink .SingleLinkSize {
  font-size: 15px;
  font-weight: bold;
}
.SingleLink:hover {
  cursor: pointer;
}
/* .SingleLink .SingleLinkSize:hover{
    color: red;
} */
.AccordinoList {
  margin-bottom: 50px;
}

.AccordinoList .ListItem {
  margin-top: 20px;
}

.AccordinoList .ListItemText {
  margin-left: 15px;
  font-size: 16px;
  font-weight: bold;
}

.SideBar {
  background: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.SideBarBottom {
  display: flex;
  justify-content: space-between;
  align-items: last baseline;
  gap: 60px;
  bottom: 0;
  padding-bottom: 20px;
  margin-left: 20px;
  margin-right: 25px;
}

.AccordinoList .PremimumPlan {
  background: #ff8100;
  padding: 8px;
  border-radius: 5px;
  margin-right: 8px;
}

.AccordinoList .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg);
}

.dashboard-sidebar {
  width: 350px;
}

.dashboard-sidebar .close-menu {
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 10px;
}

.dashboard-sidebar .close-menu .MuiIconButton-root {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-sidebar .user-info {
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
}

.dashboard-sidebar .user-info .MuiAvatar-root {
  width: 65px;
  height: 65px;
  margin-bottom: 10px;
}

.dashboard-sidebar .menu-list .list-box {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dashboard-sidebar .menu-list li:hover {
  background-color: #f9f6f6;
  cursor: pointer;
}

.dashboard-sidebar .menu-list .list-box .list-menu {
  display: flex;
  align-items: center;
  gap: 15px;
}

.dashboard-sidebar .menu-list .list-menu .image {
  border-radius: 50%;
  background-color: #ffebdb;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AccordinoList .MuiAccordionSummary-content {
  display: flex;
  align-items: center;
}

.AccordinoList .MuiAccordionDetails-root {
  display: flex;
  padding: 8px 16px 8px 50px;
}
