@import "../constants/base/colors.scss";
@import "../constants/base/global.scss";

.commonForm {
    & .formCheckbox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        & .MuiSvgIcon-root {
            fill: $ORANGE;
        }
        & .checkboxLabel {
            font-size: 15px;
            color: $DARK;
            font-family: $Gotham_Light;
            font-weight: bold;
        }
        & .link {
            padding-left: 5px;
            font-family: $Gotham_Medium;
        }
    }

    & .formCheckboxProfile {
        & .MuiSvgIcon-root {
            fill: $ORANGE;
        }
        & .checkboxLabel {
            font-size: 15px;
            color: $DARK;
            font-family: $Gotham_Light;
            font-weight: bold;
        }
        & .link {
            padding-left: 5px;
            font-family: $Gotham_Medium;
        }
    }
}
