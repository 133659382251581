.incident-breadcrumb {
  margin: 20px 20px;
}
.highlighted-incident {
  color: blue;
}
.incident-headline {
  font-size: 32px !important;
  margin: 20px 20px !important;
}

.closed {
  background: #eefbf1;
  color: #20a258;
  text-align: center;
  padding: 5px 15px;
  border-radius: 16px;
  font-size: 12px;
}
.resolved {
  background: rgb(30, 198, 91, 0.12);
  color: #1ec65b;
  text-align: center;
  padding: 5px 15px;
  border-radius: 16px;
  font-size: 12px;
}
.Unresolved {
  background: rgba(236, 135, 152, 0.12);
  color: red;
  text-align: center;
  padding: 5px 15px;
  border-radius: 16px;
  font-size: 12px;
}
.Ongoing {
  background: #e3edff;
  color: #2b6fed;
  text-align: center;
  padding: 5px 15px;
  border-radius: 16px;
  font-size: 12px;
}
.pending {
  color: #fc8434;
  background: #ffede0;
  text-align: center;
  padding: 5px 15px;
  border-radius: 16px;
  font-size: 12px;
}
.tablecell {
  text-align: left;
  align-items: start !important;
}
.Empty {
  padding: 5px 12px;
  border-radius: 25px;
  font-weight: 600;
  color: #1ec65b;
  background: #e2ffec;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 82px;
}

.Occupied {
  padding: 5px 12px;
  border-radius: 25px;
  font-weight: 600;
  color: #2b6fed;
  background: #e7efff;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 82px;
}
.No-Own {
  padding: 5px 12px;
  border-radius: 25px;
  font-weight: 600;
  background: #ffd9d9;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 82px;
  color: red;
}
.view-page-menu button {
  padding: 0px;
}
.tabs-selected .MuiTab-wrapper {
  font-weight: 600;
  font-family: "Gotham_Bold", sans-serif !important;
}

.unit_form_formik_box .input_icon__form_box label {
  margin-bottom: 10px;
}

.unit_form_formik_box .input_icon__form_box .input_box.Mui-disabled,
.unit_form_formik_box .input_icon__form_box .input_box.Mui-disabled input {
  background-color: rgb(243, 243, 243) !important;
}

.unit-family-container .user-details .heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.unit-family-container .user-details .user-menu {
  position: unset;
}

.unit-family-container .user-details {
  box-shadow: 4px 0px 14px #e9e9e9;
  padding: 20px;
  display: block;
  border-radius: 8px;
}

.unit-family-container .user-details h4 {
  margin: 0;
}

.unit-family-container .user-details .user-menu ul {
  left: -120px !important;
  top: -20px !important;
}

.unit-family-container {
  margin-top: 10px;
}

.unit-family-container .user-details .label {
  font-weight: 400;
  margin-top: 15px;
  color: lightgray;
  font-size: 14px;
}

.unit-family-container .user-details .user-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.unit-family-container .user-details .user-info p {
  font-size: 20px;
  color: black;
}
