@import "../constants/base/colors.scss";
@import "../constants/base/global.scss";
.reg-wrapper {
  & .reg-content-block {
    display: flex;
    flex-direction: column;
    & .reg-block {
      display: flex;
      flex-direction: column;
      & .reg-row {
        display: flex;
        align-items: center;
        border: 1px solid $LIGHT_GRAY_SHADE4;
        padding: 10px;
        border-radius: 10px;
        & .bank-logo {
          height: 40px;
          width: 40px;
          object-fit: contain;
        }
      }
      & .reg-right-block {
        padding-left: 10px;
        & h5 {
          color: $BLUE;
          font-weight: bold;
        }
        & h6 {
          margin: 0;
        }
      }
    }
  }
}
