@import '../constants/base/colors.scss';
@import '../constants/base/global.scss';

.card {
  & .costom-card-content {
    cursor: pointer;

    & .card-listing-row {
      display: flex;
      justify-content: space-between;
      padding-top: 8px;

      & span,
      & h5 {
        width: 50%;
      }
    }
  }

  // &.incident-card {
  &.MuiCard-root {
    box-shadow: 4px 0px 14px 0 #ececec;
    border: 2px solid #ececec;
    border-radius: 8px;

    & h4 {
      font-size: 17px;
      // font-weight: bold;
      color: #181d25;
      margin-bottom: 5px;
    }

    & .sub-title {
      margin-top: 5px;
      // font-weight: 300;
    }

    & .title-span {
      font-size: 0.9rem;
      color: #6f6e71;
      margin-bottom: 10px;
    }

    & hr {
      border: 1px solid #ececec;
    }

    & span {
      &.MuiTypography-body1 {
        font-family: $Gotham_Light;
        font-size: 16px;
        font-weight: 500;
        color: #6f6e71;

        @include mq(tablet-portrait-large-up) {
          font-size: 14px;
        }
      }
    }

    & p {
      &.MuiTypography-body1 {
        font-family: $Gotham_Light;
        font-size: 15px;
        font-weight: 500;
        color: $DARK_SHADE1;

        @include mq(tablet-portrait-large-up) {
          font-size: 16px;
        }
      }
    }

    & h4 {
      &.MuiTypography-body1 {
        font-family: $Gotham_Medium;
        font-size: 19px;
        font-weight: 600;

        @include mq(tablet-portrait-large-up) {
          font-size: 17px;
        }
      }
    }

    & h5 {
      &.MuiTypography-body1 {
        font-family: $Gotham_Light;
        font-weight: 600;
        // opacity: 0.85;
        font-size: 17px;
        padding-bottom: 10px;

        @include mq(tablet-portrait-large-up) {
          font-size: 15px;
        }
      }

      &.h5-title {
        font-weight: 300 !important;
      }
    }
  }

  & .MuiCardActions-root {
    padding: 0;
    padding-top: 5px;

    &.card-footer {
      display: flex;
      // flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      & .sub-title {
        margin: 0;
      }

      & .MuiButtonBase-root {
        & span {
          margin: 0;
        }
      }

      & .customButton {
        margin-bottom: 0;
        padding-top: 0;
      }
    }
  }

  & .card-img-row {
    // display: flex;
    overflow-x: scroll;
    width: 100%;
    padding-bottom: 10px;
    @include scrollbars();

    & .incident-dialog-video,
    & .card-img {
      height: 75px;
      width: 75px;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  margin-bottom: 25px;

  &.management-card {
    margin: 0;

    &.MuiCard-root {
      box-shadow: 3px 0px 14px 0px #cccccc5c;
    }

    & .MuiCardContent-root:last-child {
      padding-bottom: 0;
    }

    & h4 {
      margin-top: 10px;
    }

    & h5 {
      margin-bottom: 10px;
    }

    & .MuiButton-root {
      width: auto;
    }

    & .card-rows {
      display: flex;
      align-items: center;
      margin: 10px 0;

      & h5 {
        padding-left: 10px;
        margin-bottom: 10px;
        font-weight: 500;
      }
    }
  }

  &.classified-card {
    & h4 {
      &.MuiTypography-body1 {
        text-transform: capitalize;
        margin-top: 0;
      }
    }

    & .menu-btn {
      &.MuiButton-text {
        padding: 0;
        min-width: 0;
      }
    }

    & .menu-btn {
      &.MuiButton-text {
        padding: 0;
        min-width: 0;
      }
    }

    & p {
      &.MuiTypography-body1 {
        text-transform: capitalize;
        margin: 10px 0px;
        font-size: 17px;
        letter-spacing: 2px;
        font-family: $Gotham_Light;
        font-weight: 500;
        letter-spacing: 0.7px;
        line-height: 19px;

        @include mq(tablet-portrait-large-up) {
          font-size: 15px;
        }
      }
    }

    & span {
      &.MuiTypography-root {
        font-size: 16px;

        @include mq(tablet-portrait-large-up) {
          font-size: 14px;
        }
      }
    }

    & .content-row {
      display: flex;
      flex-direction: column;

      & p {
        &.MuiTypography-body1 {
          margin: 0;
        }
      }

      & .content-sub-row {
        display: flex;

        & .blocks {
          width: 50%;
        }
      }

      & .content-blocks-row {
        margin: 10px 0;
      }
    }

    & .classified-card-header {
      display: flex;
      justify-content: space-between;

      & .MuiButtonBase-root {
        &:hover {
          background-color: transparent;
        }
      }
    }

    & .card-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;

      & .left-block {
        display: flex;
        align-items: center;
        position: relative;

        & h4 {
          &.MuiTypography-body1 {
            text-transform: uppercase;
            padding-left: 10px;
            color: $ORANGE_SHADE2;
            margin: 0;
          }

          &.managerTitle {
            &.MuiTypography-body1 {
              font-size: 18px;
              width: 150px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;

              &:hover {
                // padding: 5px;
                // border-radius: 5px;
                // background-color: #ccc;
                white-space: normal;
                font-size: 16px;
              }
            }
          }
        }
      }

      & .dollar-icon {
        height: 30px;
        width: 30px;
        object-fit: contain;
      }
    }

    & .classified-footer {
      & .customButton {
        padding: 0;
      }

      & h4 {
        &.MuiTypography-body1 {
          padding-left: 0 !important;
        }
      }
    }
  }

  &.classified-type-card {
    display: flex;
    padding: 15px;
    justify-content: space-between;

    & .middle-section {
      width: 100%;
      display: flex;
      align-items: center;
      color: #d3d3d3;
      position: relative;

      & .icons {
        grid-gap: 0;
        gap: 0;
        margin-top: 0;
      }

      & .icons_ar {
        grid-gap: 0;
        gap: 0;
        margin-top: 0;
        margin-left: 10px !important;
      }

      & .radioCheckmark {
        position: absolute;
        top: 21px;
        right: 10px;
        height: 20px;
        width: 20px;
        background-color: $WHITE;
        border: 3px solid $LIGHT_GRAY_SHADE7;
        border-radius: 50%;
        cursor: pointer;

        &::after {
          content: '';
          position: absolute;
          top: 4px;
          left: 4px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: $WHITE;
        }
      }

      & .radioCheckmark_ar {
        position: absolute;
        top: 21px;
        left: 10px;
        height: 20px;
        width: 20px;
        background-color: $WHITE;
        border: 3px solid $LIGHT_GRAY_SHADE7;
        border-radius: 50%;
        cursor: pointer;

        &::after {
          content: '';
          position: absolute;
          top: 4px;
          right: 4px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: $WHITE;
        }
      }

      & input {
        display: none;

        &:checked {
          & ~ .radioCheckmark {
            border-color: $ORANGE;
          }

          & ~ .radioCheckmark:after {
            display: block;
            background: $ORANGE;
          }

          & ~ .radioCheckmark_ar {
            border-color: $ORANGE;
          }

          & ~ .radioCheckmark_ar:after {
            display: block;
            background: $ORANGE;
          }
        }
      }

      & .radioTitle {
        font-weight: bold;
        padding-left: 15px;
        color: #939292;
      }

      & .para {
        color: $LIGHT_GRAY;
      }
    }

    & .MuiCheckbox-root {
      padding: 0;
    }

    input[type='radio']:checked:after {
      width: 15px;
      height: 15px;
      border-radius: 15px;
      top: -2px;
      left: -1px;
      position: relative;
      background-color: #ffa500;
      content: '';
      display: inline-block;
      visibility: visible;
      border: 2px solid white;
    }

    & .MuiIconButton-label {
      // &::before {
      //   content: "";
      //   position: absolute;
      //   top: 6px;
      //   left: 6px;
      //   background-color: $ORANGE;
      //   padding: 6px;
      //   border-radius: 50%;
      // }
    }
  }

  &.incident-detail-card {
    & .MuiCardContent-root {
      padding: 0;
    }

    &.MuiCard-root {
      padding: 0 !important;
      padding-bottom: 15px !important;
    }

    & .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e6e6e6;
      padding-bottom: 15px;
      padding: 15px;
    }

    & .MuiOutlinedInput-input {
      padding: 10px 60px 10px 14px;
    }

    & .customSelect {
      margin-right: 10px;
      margin-bottom: 0;

      & .MuiOutlinedInput-root {
        padding-left: 0;
      }

      &.danger {
        background-color: transparent !important;

        & #demo-simple-select-outlined,
        & .MuiInputBase-input {
          color: #d84755 !important;
          font-weight: bold;
        }

        & .MuiSelect-icon {
          color: $WARNING_SHADE1;
        }

        & .MuiOutlinedInput-root {
          background-color: #ffeded;
          border: 0 !important;
        }

        & .MuiSelect-outlined {
          &.MuiSelect-outlined {
            background-color: #ffeded;
          }
        }
      }

      &.success {
        & .MuiOutlinedInput-root {
          background-color: $GREEN_SHADE1;
        }

        & #demo-simple-select-outlined,
        & .MuiInputBase-input {
          color: $GREEN !important;
          font-weight: bold;
        }

        & .MuiSelect-icon {
          color: $GREEN;
        }

        & .MuiOutlinedInput-root {
          background-color: $GREEN_SHADE1;
          border: 0 !important;
        }

        & .MuiSelect-outlined {
          &.MuiSelect-outlined {
            background-color: $GREEN_SHADE1;
          }
        }
      }

      &.warning {
        & .MuiOutlinedInput-root {
          // background-color: $ORANGE_SHADE1;
        }

        & #demo-simple-select-outlined,
        & .MuiInputBase-input {
          color: $ORANGE !important;
          font-weight: bold;
        }

        & .MuiSelect-icon {
          color: $ORANGE;
        }

        & .MuiOutlinedInput-root {
          background-color: $ORANGE_SHADE1;
          border: 0 !important;
        }

        & .MuiSelect-outlined {
          &.MuiSelect-outlined {
            background-color: $ORANGE_SHADE1;
          }
        }
      }
    }

    & .customButton {
      & .MuiButton-root {
        padding: 5px 15px;
      }
    }

    & .card-content {
      padding: 20px 20px 0 20px;
      display: flex;
      flex-direction: column;
    }

    & .row-block {
      display: flex;
      flex-direction: column;
    }

    & .card-rows {
      display: flex;
      align-items: center;
      margin: 10px 0;
      border-bottom: 1px solid #f0f0f0;

      & h4 {
        padding-left: 20px;
      }
    }

    & .photos-row {
      display: flex;
    }
  }

  &.reporting-card {
    margin-bottom: 20px;

    & .reporting-row {
      display: flex;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      & .icons {
        height: 30px;
        width: 30px;
        object-fit: contain;
      }

      & .reporting-right-block {
        flex-direction: column;
        margin-left: 10px;

        & h5 {
          margin-bottom: 5px;
          font-weight: 500;
        }
      }
    }
  }

  &.confirmation-card {
    & .confirmation-card-content {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 20px;

      & .info-row {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;

        & .info-icon {
          height: 50px;
          width: 50px;
          object-fit: contain;
        }
      }

      & h3 {
        &.MuiTypography-body1 {
          margin: 10px 0;
          font-size: 24px;

          @include mq(tablet-portrait-large-up) {
            font-size: 20px;
          }
        }
      }

      & p {
        font-size: 14px;
        font-weight: 600;
        margin-top: 10px !important;
        color: gray;

        @include mq(tablet-portrait-medium-up) {
          width: 70%;
          margin: 0 auto;
        }
      }

      & .customButton {
        padding-bottom: 0;

        & .formGroup {
          display: flex;
          justify-content: center;
          gap: 15px;

          & .MuiButtonBase-root {
            width: auto;
            margin-right: 5px;
            padding: 10px 15px;
          }

          & .MuiButton-label {
            font-size: 11px;
          }

          & .reject-closure-btn {
            background-color: white;
            border-color: #2c6fed;
            color: #2c6fed !important;
          }

          & .reject-closure-btn span {
            color: #2c6fed !important;
          }

          & .confirm-closure-btn {
            color: white;
            border-color: #2c6fed;
            background-color: #2c6fed !important;
          }

          & .confirm-closure-btn span {
            color: white !important;
          }
        }
      }
    }
  }

  &.neighbour-card {
    box-shadow: none;
    border: 0;
    margin-bottom: 10px;
    cursor: pointer;

    &.neighbour-list-card {
      &:hover {
        box-shadow: 1px 1px 9px 0px #cccc;
      }
    }

    & .MuiCardContent-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    & h4 {
      &.MuiTypography-body1 {
        margin: 10px 0;
        font-size: 17px;

        @include mq(tablet-portrait-large-up) {
          font-size: 14px;
        }
      }
    }

    & h5 {
      &.MuiTypography-body1 {
        font-weight: 500;
      }
    }

    & .social-raw {
      display: flex;
      background-color: #f6f6f6;
      padding: 12px;
      border-radius: 20px;
      margin-top: 10px;

      @include mq(tablet-portrait-large-up) {
        padding: 12px;
      }

      & .blocks {
        border-right: 2px solid #d7dbe4;

        &:last-child {
          border-right: 0;
        }

        & .icons {
          height: 18px;
          width: 18px;
          object-fit: contain;
          border-radius: 0;

          @include mq(tablet-portrait-large-up) {
            height: 15px;
            width: 15px;
          }
        }

        padding: 0 10px;
      }
    }

    & .info-icon {
      height: 50px;
      width: 50px;
      object-fit: contain;
    }
  }

  &.neighbour-detail-card {
    width: 450px;
    margin: 0 auto;

    & .card-content {
      align-items: flex-start;

      & .card-top-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
    }

    & .MuiCardContent-root {
      text-align: left;
    }

    @include mq(tablet-portrait-large-up) {
      width: 100%;
    }

    & .social-raw {
      position: relative;

      &::before {
        content: ' ';
        position: absolute;
        top: 20px;
        left: -100%;
        border-bottom: 1px solid #f6f6f6;
        width: 100%;
      }

      &::after {
        content: ' ';
        position: absolute;
        top: 20px;
        right: -100%;
        border-bottom: 1px solid #f6f6f6;
        width: 100%;
      }
    }

    & .card-content {
      padding: 0;

      & .bio-content-row {
        // width: 100%;
        padding: 0 16px 16px 16px;

        & .bio-row {
          & h4 {
            &.MuiTypography-body1 {
              margin-top: 0;
            }
          }

          & .customButton {
            display: flex;
            padding-top: 0;

            & .contain {
              margin-right: 10px;
            }

            & .MuiButton-root {
              border-radius: 20px;
              padding: 10px;
            }
          }
        }

        & .social-data-row {
          & .icons-row {
            & .MuiButtonBase-root {
              padding: 0;

              &:hover {
                background-color: transparent;
              }
            }

            display: flex;

            & .icon {
              margin-right: 5px;
              height: 45px;
              width: 45px;
              object-fit: contain;
            }
          }
        }
      }
    }
  }

  &.classificationPre-card {
    & h5 {
      &.MuiTypography-body1 {
        font-size: 18px;
      }
    }

    & span {
      &.MuiTypography-body1 {
        font-weight: 300;
        color: #c6c4cd;
      }
    }
  }

  &.classifiedManager-card {
    & .MuiButton-contained {
      &.contain {
        & span {
          white-space: normal;
        }
      }
    }
  }

  &.facilityReserve-card {
    text-align: center;
    margin-bottom: 10px;

    & .card-footer {
      justify-content: center;
    }

    & h4 {
      &.MuiTypography-body1 {
        font-family: $Gotham_Bold !important;
        font-weight: 600;
        font-size: 15px;
        margin: 15px 0;
      }
    }

    & h5 {
      &.MuiTypography-body1 {
        font-family: $Gotham_Light !important;
        font-weight: 100;
        font-size: 14px;
      }
    }

    & .customButton {
      padding-bottom: 0;

      & .MuiButton-contained {
        &.contain {
          padding: 5px 45px;
        }
      }
    }
  }

  &.facility-card {
    & .facility-subtitle {
      font-weight: bold !important;
      color: $DARK !important;
    }

    & .span-subtitle {
      color: $DARK !important;
    }
  }
}

.community__user_details {
  box-shadow: 4px 0px 14px #e9e9e9 !important;
  border-radius: 8px;
  position: relative;

  .community__user_menu {
    position: absolute;
    top: 10px;
    right: 10px;

    svg {
      color: #aaa;
    }
  }

  img {
    border-radius: 100%;
    width: 70;
    height: 70;
    border: 1px solid #f0f0f0;
  }

  .building-unit-box {
    margin: 10px 0 0;
  }

  p {
    margin-top: 12px;
    font-size: 15px;
  }

  label {
    font-size: 13px;
    margin: 8px 0;
    color: #aaa;
  }

  .roles-box {
    margin-top: 12px;

    .role {
      padding: 5px 12px;
      border-radius: 25px;
      background: #e4edff;
      color: #2b6fed;
      font-weight: 600;
      font-size: 12px;
    }
  }

  .community_card_top,
  .community_card_bottom {
    padding: 20px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  p.name_heading {
    font-size: 18px;
  }

  .community_card_bottom {
    padding: 10px 15px 20px 15px;
    .request_details {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 10px;
      p {
        font-size: 12px;
        margin-bottom: 5px;
        margin-top: 0;
      }
      p span {
        font-weight: bold;
      }
    }
  }
}
