@import "colors.scss";

@mixin scrollbars() {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $BLUE;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $BLUE;
  }
}

@mixin mq($size) {
  @if $size ==phone-only {
    @media (min-width: 599px) {
      @content;
    }
  } @else if $size ==tablet-portrait-medium-up {
    @media (min-width: 960px) {
      @content;
    }
  } @else if $size ==tablet-portrait-large-up {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $size ==tablet-portrait-tablet-up {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $size ==tablet-landscape-up {
    @media (max-width: 959px) {
      @content;
    }
  } @else if $size ==desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size ==big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
