@import "../constants/base/colors.scss";
@import "../constants/base/global.scss";

// .dialog-wrapper {
.diloag-body {
  padding: 0;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: space-between;

  & .MuiDialogTitle-root {
    padding: 16px 0;
  }

  & .diloag-content-body {
    padding: 15px;
  }

  .diloag-header {
    // padding-bottom: 20px;
    // text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #ccc;

    .MuiButton-text {
      min-width: auto;
    }

    & .close-icon {
      height: 30px;
      width: 30px;
      object-fit: contain;
    }

    & .tenet-logo {
      height: 60px;
      width: 60px;
      object-fit: contain;
    }

    & p {
      padding-top: 10px;
    }

    & .alert-dialog-title {
      & h2 {
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
        width: 400px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include mq(tablet-portrait-large-up) {
          width: 100px;
        }
      }
    }
  }

  & .incident-dialog-video,
  & .incident-dialog-photo {
    height: 350px;
    width: 500px;
    object-fit: cover;
    border-radius: 10px;

    @include mq(tablet-portrait-large-up) {
      height: 200px;
      width: 100%;
    }
  }

  & .MuiDialogTitle-root {
    padding: 0;
  }

  & .MuiOutlinedInput-input {
    padding: 10px 32px 10px 14px;
  }

  & .diloag-content {
    padding: 15px;

    &.diloag-management-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      text-align: center;
      & .deleteModalBtns {
        display: flex;

        & .MuiButton-contained {
          &:hover {
            & .MuiButton-label {
              color: #fff !important;
            }
          }
        }

        & .MuiButton-outlined {
          border: 0;

          &:hover {
            background-color: transparent;
          }

          & .MuiButton-label {
            color: #959595;
            font-weight: bold;
          }
        }
      }
      & .delete-btn
      {
        color: #8e8e8e;
      }
      & button {
        margin: 0px 10px;
      }

      & p {
        padding: 20px 0px;
      }
      & h3
      {
        font-weight: bold;
      }
    }

    & .customSelect {
      margin-bottom: 15px;
    }

    & .incident-dialog-photo {
      height: 300px;
      width: 500px;
      object-fit: contain;
      border-radius: 10px;
    }
  }

  .dialog-footer {

    // padding-top: 20px;
    & .MuiDialogActions-root {
      flex-direction: column;
      justify-content: center;
    }

    & button {
      margin-bottom: 10px;
    }
  }
}

.provider-dialouge-body {
  min-width: 600px;

  & .dialouge-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }

  & .MuiDialogTitle-root {
    padding: 0;
  }

  & .alert-dialog-title {
    font-weight: bold;
  }

  & .close-icon {
    height: 30px;
    width: 30px;
    object-fit: contain;
  }

  & .diloag-content {
    padding: 15px;

    & .customSelect {
      margin-bottom: 15px;
    }
  }

  & .MuiOutlinedInput-input {
    padding: 10px 32px 10px 14px;
  }

  & .customSelect {
    margin-right: 10px;
    margin-bottom: 0;

    & .MuiOutlinedInput-root {
      padding-left: 0;
      background-color: $WHITE;
    }
  }

  & .customButton {
    display: flex;
    justify-content: flex-end;

    & .MuiButton-root {
      padding: 10px 20px;
      width: auto;
      margin-left: 10px;
    }
  }
}

.classified-dialouge-body {
  & .classified-header {
    border: 0;
  }

  & .dialouge-header {
    & .MuiTypography-h6 {
      font-weight: 600;
    }
  }

  & .diloag-content {
    & h3 {
      font-weight: 600;
      padding-top: 20px;
    }

    .lead {
      padding: 10px 0 !important;
    }

    &.classified-content {
      padding: 20px 40px;

      @media only screen and (max-width: 768px) {
        padding: 10px;
      }
      & .customButton {
        display: flex;
        justify-content: center;
    
        & .MuiButton-root {
          padding: 8px 30px;
          width: auto;
          margin-left: 10px;
        }
      }
    }
  }

  & textarea {
    padding: 0px 20px !important;
  }

  & .textarea {
    height: 150px !important;
  }

  & .customButton {
    padding-top: 60px;

    & .MuiButton-root {
      padding: 7px 40px;
    }

    & .MuiButton-outlined {
      border: 1px solid #2c6fed61;
    }

    & .MuiButton-contained {
      &:hover {
        & span {
          // color: $BLUE;
        }
      }
    }

    & .MuiButton-outlined {
      & span {
        color: $BLUE;
      }

      &:hover {
        & span {
          color: $WHITE;
        }
      }
    }

    &.diloag-btn {
      padding-top: 10px;
      padding-bottom: 50px;
      width: 100%;

      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }

      & button {
        width: 50%;

        @media only screen and (max-width: 600px) {
          width: auto;
          margin-bottom: 10px;
        }
      }

      & .MuiButton-root {
        padding: 12px 15px;
      }
    }
  }
}

// .faciliteDeleteDialog
// {
  
// }