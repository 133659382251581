@import "../constants/base/colors.scss";
@import "../constants/base/global.scss";
.neighbour-listing-wrapper {
  overflow: hidden;
  & .content-block-wrapper {
    & .neighbour-form {
      padding-top: 20px;
      & .neighborSelect {
        & .MuiOutlinedInput-input {
          padding-left: 30px;
        }
      }
    }
  
    &.common-incident-block {
      @include scrollbars();
      overflow-y: scroll;
      overflow-x: hidden;
      margin: 10px;
      height: 100vh;
      background-color: #f8f9fe;
      @include mq(tablet-portrait-medium-up) {
        width: 450px;
        margin: 10px auto;
      }
    }
  }
  & .content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top:15px;
    padding-bottom: 10px;
    padding-left: 8px;
    padding-right:10px;
    border-bottom: 2px solid #f2efef;
    & .blocks {
      display: flex;
      align-items: center;
      & .MuiSvgIcon-root {
        height: 1.5em;
        width: 1.5em;
      }
      & .MuiButton-text {
        padding: 0;
        min-width: auto;
      }
    }
    & h4 {
      padding-left: 10px;
      white-space: nowrap;
      &.neighbor-title {
        display: none !important;
      }
    }
    & .icons {
      padding: 0 5px;
    }
    & .MuiButton-root {
      &:hover,
      &:active {
        background-color: transparent;
      }
    }
    & .searchTexfieldBox {
      width: 100%;
      margin: 0;
      padding: 0 20px;
      & .MuiOutlinedInput-input {
        padding: 0;
        font-weight: 600;
        color: #949494;
        &::placeholder {
          font-weight: 600;
        }
      }
    }
  }
  & .note-row {
    padding-top: 20px;
    & .title {
      color: $BLUE;
    }
  }
  & .neighbour-detail-section {
    
    & .relation-row {
      display: flex;
      justify-content: space-around;
      padding: 15px 0 10px 0;
      & h4
      {
        &.MuiTypography-body1
        {
          padding: 0 10px;
        }
      }
      & .blocks {
        margin: 0 15px;
        & .title {
          font-weight: 100;
          font-family: $Gotham_Light;
          padding-left: 5px;
          @include mq(tablet-portrait-large-up) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
