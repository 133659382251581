@import "../constants/base/colors.scss";
@import "../constants/base/global.scss";

.commonForm {
  width: 100%;

  @include mq(tablet-portrait-large-up) {
    width: auto;
  }

  & .frm-icons {
    // height: 24px;
    // width: 24px;
    object-fit: contain;
  }

  & .frm-title {
    font-weight: 600;
    margin-bottom: 20px;
  }

  & .visitorTextfield {
    .MuiOutlinedInput-input {
      padding: 15px 10px;
    }

    &.CreateClassifiedFrm {}

    & .classifiedFormGroup {
      width: auto;
      margin-right: 5px;
    }

    & .MuiInputAdornment-positionStart {
      // width: auto;
      // margin-right: 5px;
    }
  }

  & .formGroup {
    width: 100%;
    position: relative;
    margin-bottom: 15px;

    & .formInputGrp {
      position: relative;
      margin-top: 15px;
      margin-bottom: 5px;
    }

    & .formInput {
      width: 100%;
      padding: 18px 18px 18px 50px;
      color: #b5b5b5;
      border-radius: 25px;
      border: 1px solid #e9dede;
      background-color: #f9f9f9;
      font-size: 16px;
      outline: 0;

      &::placeholder {
        color: #b9b9b9;
        font-weight: 500;
      }
    }

    & .formInput_select_v {
      width: 100%;
      padding: 15px 15px 15px 10px;
      color: #212121;
      border-radius: 25px;
      border: 1px solid #e9dede;
      background-color: #f9f9f9;
      font-size: 16px;
      outline: 0;

      &::placeholder {
        color: #b9b9b9;
        font-weight: 500;
      }
    }

    & .formInput_select_family {
      width: 100%;
      color: #b5b5b5;
      border-radius: 25px;
      border: 1px solid #e9dede;
      background-color: #f9f9f9;
      font-size: 16px;
      outline: 0;

      &::placeholder {
        color: #b5b5b5;
        font-weight: 500;
      }
    }

    & .formInput_select_s {
      width: 100%;
      color: #212121;
      border-radius: 25px;
      border: 1px solid #e9dede;
      background-color: #f9f9f9;
      font-size: 16px;
      outline: 0;

      &::placeholder {
        color: #b9b9b9;
        font-weight: 500;
      }
    }



    & .formInput_ar {
      width: 100%;
      padding: 18px 50px 18px 18px;
      color: #b5b5b5;
      border-radius: 25px;
      border: 1px solid #e9dede;
      background-color: #f9f9f9;
      font-size: 16px;
      outline: 0;

      &::placeholder {
        color: #b9b9b9;
        font-weight: 500;
      }
    }

    & .otpInputGrp {
      display: flex;
      justify-content: center;
    }

    &.otpBlock {
      &>div {
        justify-content: space-between;
        width: 100%;
      }

      & .formOutlineInput {
        & input {
          width: 10%;
          padding: 10px 0;
          color: $DARK;
          border: 0;
          outline: 0;
          font-size: 28px;
          font-weight: 500;
          margin: 0 10px;
          border-bottom: 2px solid $DARK;
          text-align: center;
        }
      }
    }

    & .frmLeftIcons {
      position: absolute;
      left: 20px;
      top: 32%;
      color: #b9b9b9;
    }

    & .frmrightIcons {
      position: absolute;
      right: 20px;
      top: 14px;
      color: #b9b9b9;
      cursor: pointer;
    }

    & .textfieldLabel {
      font-size: 14px;
      margin-bottom: 10px;
    }

    &.customFileupload {
      & .imgLayer {
        position: absolute;
        width: 100%;
        /* left: 0px; */
        margin: 0 auto;
        top: -66px;
        z-index: 1;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: #F9F9F9;
          border: 2px dashed rgb(209,209,209);
          z-index: 2;
          border-radius: 20px;
          // border: 1px solid #e9dede;
        }

        & .bg-img {
          height: 150px;
          width: 100%;
          object-fit: cover;
          border-radius: 20px;
        }
      }

      & .uploadLayer {
        position: absolute;
        left: 5px;
        top: -57px;
        z-index: 3;
        height: 135px;
        width: 98%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 20px;

        // background: rgba(0, 0, 0, 0.6);
        & .content-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          align-items: center;
          padding-top: 30px;

          & .upload-icon {
            margin-top: 40px;
          }
        }
      }

      & .result-disp-row {
        display: flex;
        align-items: center;
        margin-top: 10px;

        & .successful-icon {
          height: 25px;
          width: 25px;
          object-fit: contain;
        }

        & .error-icon {
          height: 35px;
          width: 35px;
          object-fit: contain;
        }

        & .text-success {
          margin-left: 10px;
          text-transform: capitalize;
        }
      }

      & .MuiButtonBase-root {
        width: 100%;
        // padding: 20px;
        min-height: 150px;
        background-color: #f9f9f9;
        border-radius: 25px;
        box-shadow: none;
        padding: 0;

        & .MuiButton-label {
          text-transform: capitalize;
          font-size: 20px;
          color: #b5b5b5;
          position: relative;
          padding-top: 20px;
        }

        & .upload-icon {
          position: absolute;
          top: 0;
          // left: 50%;
        }
      }
    }

    &.textarea {
      // margin-bottom: 0;
      height: 70px;
      background-color: $WHITE;
      // box-shadow: 4px 0px 14px 0 #ececec;
      border: 1px solid #ececec;
      border-radius: 25px;
      padding: 20px 0;
      position: relative;

      & .clipboard-icon {
        position: absolute;
        top: 18px;
        left: 15px;
        z-index: 2;
      }
    }

    & textarea {
      width: 100% !important;
      height: 70px !important;
      background-color: $WHITE;
      border: 0;
      color: $LIGHT_GRAY_SHADE3;
      outline: 0;
      font-size: 15px;
      padding: 0 20px 0 45px;
      font-weight: 600;
      font-family: $Gotham_Light;
      line-height: 17px;

      &::placeholder {
        color: $LIGHT_GRAY_SHADE3;
        font-weight: 500;
        font-family: $Gotham_Light;
      }
    }
  }

  &.CreateClassifiedFrm {
    & .classifiedFormGroup {
      width: auto;
      margin-right: 5px;
    }

    & .formGroup {
      width: auto;
    }

    & .MuiInputAdornment-positionStart {
      display: none;
    }

    & .fileuploadBlock {
      display: flex;
      // @include scrollbars();
      width: 100%;

      & .buyersFileupload {
        & .MuiButtonBase-root {
          width: 90px;
          min-height: 90px;
          border-radius: 10px;
        }

        margin-right: 10px;
      }

      & .imgLayer {
        position: relative;
        margin-right: 10px;

        & .buyerphoto {
          width: 90px;
          height: 90px;
          object-fit: contain;
          border-radius: 10px;
        }

        & .close_icon {
          position: absolute;
          top: 3px;
          right: -5px;
          z-index: 2;
          width: 30px;
          height: 30px;
          object-fit: contain;
        }
      }
    }

    & .sellerPriceBox,
    & .classifiedPriceBlock {
      position: relative;

      & .priceSelect {
        position: absolute;
        top: 20px;
        right: 0;
        width: 40%;

        & .MuiInputBase-root,
        & .MuiSelect-outlined {
          padding: 0 !important;
          margin: 0 !important;
          background-color: transparent;
          border: 0;
          position: relative;
        }

        & .MuiSelect-root {
          color: $ORANGE;
          font-size: 13px;
          font-weight: bold;
          text-transform: uppercase;
          position: relative;

          &::before {
            content: "";
            top: 1px;
            right: 20px;
            position: absolute;
            width: 8px;
            height: 8px;
            border: 4px solid #fc8434;
            border-radius: 2px;
            border-left: 0;
            border-top: 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        & .MuiSelect-icon {
          display: none;
          position: relative;
        }
      }

      & .priceSelect_ar {
        position: absolute;
        top: 20px;
        left: 0;
        width: 40%;

        & .MuiInputBase-root,
        & .MuiSelect-outlined {
          padding: 0 !important;
          margin: 0 !important;
          background-color: transparent;
          border: 0;
          position: relative;
        }

        & .MuiSelect-root {
          color: $ORANGE;
          font-size: 13px;
          font-weight: bold;
          text-transform: uppercase;
          position: relative;

          &::before {
            content: "";
            top: 1px;
            left: 20px;
            position: absolute;
            width: 8px;
            height: 8px;
            border: 4px solid #fc8434;
            border-radius: 2px;
            border-left: 0;
            border-top: 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        & .MuiInputBase-formControl{
          width:150px;
        }

        & .MuiSelect-icon {
          display: none;
          position: relative;
        }
      }
    }


    & .classifiedPriceBlock {
      display: flex;
      /* align-items: center; */
      justify-content: space-between;
      margin: 10px;

      & .priceSelect {
        position: unset;

        & .MuiSelect-root {
          &::before {
            content: "";
            top: 0px;
            right: 5px;
          }
        }
      }
    }
  }
}

.desktop-ui {
  & .commonForm {
    @include mq(tablet-portrait-medium-up) {
      & .formGroup {
        border-radius: 15px;

        & .formInput {
          border-radius: 25px;
        }

        &.textarea {
          border-radius: 10px;
        }
      }
    }
  }
}

.autoCompleteSearchComplex input{
  padding-left: 45px !important;
  text-align: left;
}

.autoCompleteSearchComplex .MuiAutocomplete-input :placeholder-shown {
  color: pink;
}

#combo-box-demo::placeholder{
  padding-left: 0px;
}

//
//.autoCompleteSearchComplex input::-webkit-input-placeholder {
//  margin-left: -90px;
//  color: red;
//}
//
//.autoCompleteSearchComplex input:-moz-placeholder {
//  margin-left: -90px;
//  color: red;
//}
//
//.autoCompleteSearchComplex input::placeholder {
//  margin-left: -90px;
//  color: red;
//}
//
//.autoCompleteSearchComplex input::-webkit-input-placeholder {
//  margin-left: -90px;
//  color: red;
//}

.incident-wrapper {
  .commonForm .formGroup .frmLeftIcons {
    left: 15px;
    top: 18px;
  }
  .commonForm .formGroup .frmRightIcons {
    right: 15px;
    top: 18px;
  }
}

.CreateClassifedBottomButton{
  width:300px;
  z-index: 999;
}

@media only screen and (max-width: 425px) {
  .forgotPasswordButton {
    position:fixed;
    bottom: 10px;
    left:20px;
    width: 100%;
  }
  .forgotPasswordInnerButton {
    width: 90% !important;
  }
  .familyFormButton{
    position:fixed;
    bottom: 10px;
    left:20px;
    width: 100%;
  }

  .familyFormButton_ar{
    position:fixed;
    bottom: 10px;
    right:20px;
    width: 100%;
  }

  .familyFormBottomButton{
    width:89% !important;
    z-index: 999;
  }

  .CreateClassifedFormButton{
    position:fixed;
    bottom: 10px;
    left:20px;
    width: 100%;
  }
  .CreateClassifedBottomButton{
    width:89% !important;
    z-index: 999;
  }
  .SelectTypeClassifedBottomButton{
    width:88% !important;
    z-index: 999;
  }
}
