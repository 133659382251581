.Documents_Container {
  border-radius: 8px;
  background: #fff;
  box-shadow: 4px 0px 14px 0px #e9e9e9;
  padding: 25px 0px 10px 25px;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
.Documents_Div {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Documents_Number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 29px;
  border-radius: 15px;
  background: rgb(255, 248, 243);
  color: #fc8434;
  text-align: center;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
}
.Documents {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.Documents_Div_Text {
  color: #181d25;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
}
.Documents_Div_Img {
  width: 15px;
  height: 19.5px;
  margin-right: 16px;
}
.Margin_Doc_Policy {
  margin-bottom: 0px !important;
}
.Document_Tag_Container {
  margin-bottom: 20px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Doc_Upload_Button {
  width: 207px;
  height: 43px;
  background-color: #2b6fed;
  border: none;
  border-radius: 8px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  font-family: inherit;
  font-size: 14px;
  text-transform: uppercase;
}
.Documents_Lists_Container {
  padding: 25px 25px 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 4px 0px 14px 0px #e9e9e9;
  margin-bottom: 30px;
}
.Documents_List_Value {
  height: 70px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 28px 0 20px;
  margin-bottom: 15px;
}
.Documents_Value_Txt {
  color: #181d25;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
.menudot {
  width: 4px;
  height: 16px;
  cursor: pointer;
}
.Options_Bottom {
  margin-bottom: 12px;
}
.Options_Top {
  margin-top: 15px;
  margin-bottom: 15px;
}
.Docs_Options {
  color: #020433;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  padding: 0 20px;
}
.Docs_Options:hover {
  background-color: rgb(238, 238, 238);
}
.Documents_Popper_Button {
  min-width: 10px !important;
  padding: 0 !important;
  text-transform: uppercase;
}

.Documents_Popper .MuiPaper-elevation8 {
  box-shadow: 0px 0px 6px rgba(87, 148, 201, 0.18) !important;
  border-radius: 8px;
}
.Documents_Popper .MuiPopover-paper {
  margin-top: 12px;
  margin-left: 1.2%;
  overflow: unset;
}
.Documents_Popper .MuiPopover-paper::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -9px;
  left: 50%;
  margin-left: 17px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent rgb(255, 255, 255) transparent;
}
.Document_Model_Height {
  height: auto !important;
}
.Modal_Input_Field {
  outline: none;
  height: 56px;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  background: #f9f9f9;
  padding: 0 15px;
}
.File_Details {
  border-radius: 8px;
  background: #fff;
  box-shadow: 4px 0px 14px 0px #e9e9e9;
  min-height: 70vh;
  padding: 25px;
}
.File_Details_Heading {
  color: #000;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 14px;
}
.File_Details_Text {
  color: #8d8d8d;
  text-align: justify;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.398px;
  margin-bottom: 20px;
}
.second-body-grid {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  margin: 0 !important;
  width: 100% !important;
}
.second-body-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  padding: 15px 20px;
}
.File_Details iframe {
  width: 100%;
  height: 600px;
  border: 0;
}

@media (max-width: 500px) {
  .Document_Tag_Container {
    display: block;
  }
  .Doc_Upload_Button {
    width: fit-content;
    height: fit-content;
    margin-top: 10px;
    padding: 10px 15px;
    font-size: 10px;
    text-transform: uppercase;
  }
  .Documents_Popper_Button {
    width: 30px;
  }
  .Documents_List_Value {
    padding: 0 10px;
  }
}

.Show .ManageFAQ_Tag {
  margin-bottom: 0px;
}

.comp-emp-item {
  display: flex;
  align-items: center;
}