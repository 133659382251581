.commonForms .formGroups .formInputs {
  padding: 18px 18px 18px 18px;
  box-sizing: border-box;
  color: #212121;
  /* border-radius: 25px; */
  /* border: 1px solid #f4f4f4; */
  background-color: #F0F0F0;
  font-size: 16px;
  outline: 0;
}
.placeColor .MuiTypography-colorTextSecondary{
  color:"#BEBEBE" !important
}
.formInputs {
  padding: 18px 18px 18px 18px;
  color: #212121;
  width: 100%;
  border-radius: 8px;
  border: none;
  background-color: #F0F0F0;
  font-size: 16px;
  outline: 0;
  border: 0.1px solid rgb(209, 209, 209) !important
}

.commonForms .formGroups .formInputs1 {
  padding: 18px 18px 18px 18px;
  color: #212121;
  /* border-radius: 25px; */
  /* border: 1px solid #f4f4f4; */
  background-color: #F0F0F0;
  font-size: 16px;
  outline: 0;
}

.formInputs1 {
  padding: 18px 18px 18px 18px;
  color: #212121;
  width: 100%;
  border-radius: 8px;
  background-color: #F0F0F0;
  border: 0.1px solid rgb(209, 209, 209) !important;
  font-size: 16px;
  outline: 0;
  height: 202px;
}

.formInputs2 {
  padding: 18px 18px 18px 18px;
  color: #FC8434;
  width: 100%;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  outline: 0;
}
.formInputs .MuiInput-underline:before, .formInputs .MuiInput-underline:after{
  border-color: transparent !important;
}
.formInputs  .MuiInputBase-input{
  padding: 0;
}
.MuiPaginationItem-page.Mui-selected {
  background: #FC8434;
}

.formInputs .MuiTypography-colorTextSecondary {
  color: #BEBEBE
}

.select .MuiSelect-outlined.MuiSelect-outlined{
  background-color: #F0F0F0;
}

#form-file-upload {
  height: 16rem;
  width: 28rem;
  /* max-width: 100%; */
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100px;
  width: 168px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.buttonSave {
  width: 177px !important;
  height: 56px !important;
  background: #2B6FED !important;
  border-radius: 8px !important;
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
  color: #FFFFFF !important;
  font-weight: bold !important;
  padding: 0px 20.5px !important;
  text-transform: capitalize !important;
}

.buttonCancel {
  color: #2B6FED !important;
  font-weight: bold !important;
  padding: 0px 20.5px !important;
  text-transform: capitalize !important;
  width: 177px !important;
  height: 56px !important;
  border: 1px solid #2B6FED !important;
  border-radius: 8px !important;
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;

}

.typographyLable1 {
  font-size: 14px !important;
  color: #212121 !important;
  margin-top: 11px !important;
  margin-bottom: 10px !important;
}

.typographyLable {
  font-size: 14px !important;
  color: lightgrey !important;
  margin-top: 11px !important;
  margin-bottom: 10px !important;
}

.errorMSG{
  color: #fc3e34;
  font-size: 14px;
}

.boxDisplayFlex {
  display: flex !important;
  flex-wrap: wrap !important;
  width: 100% !important;
  justify-content: space-between !important;
}

.boxDisplayFlexNoWrap {
  flex-wrap: nowrap !important;
}

.topBarName {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 25px !important;
}

.openPopup {
  margin-top: 24px !important;
  justify-content: space-between !important;
  display: flex !important;
  width: inherit !important;
  align-items: center !important;
  border-radius: 1px solid #e7e7e7 !important;
  padding-bottom: 24px !important;
}

.topBarFormik {
  margin-top: 30px !important;
  border-radius: 8px !important;
  background-color: #FFF !important;
  box-shadow: 4px 0px 14px 0px #E9E9E9 !important;
  padding: 25px !important;
}


/* marginBottom: "0px", border: "1px solid #F0F0F0", borderRadius: "8px", backgroundColor: "#F0F0F0" */
.formgropTextFild {
  margin-bottom: 0px !important;
  border: 1px solid #F0F0F0 !important;
  border-radius: 8px !important;
  background-color: #F0F0F0 !important;
}

.fontSizeBox {
  color: #181D25 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}