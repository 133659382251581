.facility-manager .customSelect .MuiOutlinedInput-root {
  border: 1px solid #f0f0f0 !important;
}

.facility-manager .commonForm.CreateClassifiedFrm .MuiInputAdornment-positionStart {
  display: block !important;
}

.facility-manager .facility-date {
  width: 100%;
  border-radius: 25px;
  border: 1px solid #f0f0f0;
  background: #f9f9f9;
  position: relative;
  padding: 18px 0 18px 0;
}

.facility-manager .facility-date::before,
.facility-manager .facility-date::after {
  position: unset;
  content: none;
}

.facility-manager .facility-date input {
  padding: 0 10px 0 42px;
}

.facility-manager .facility-date img {
  top: 18px;
  left: 16px;
  position: absolute;
}

.placeHolderForDate input[type="date"]:not(.has-value):before {
  color: #7a7878;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}

.placeHolderForDate input[type="date"]:focus:before {
  content: "" !important;
}

.incident-wrapper .content-block-wrapper.common-incident-block.create-reservation-wrapper .reservationDec {
  padding: 14px;
}

.incident-wrapper.success-page .content-block {
  display: block;
}
.incident-wrapper.success-page .main-content-block {
  height: 100%;
  flex-direction: unset;
}
.incident-wrapper.success-page .header-block {
  padding-top: 0;
  align-items: center;
  margin-top: 200px;
  padding-bottom: 200px;
  justify-content: center;
  gap: 10px;
}
.filtered-facility-list .card.facility-card .span-subtitle {
  color: #8c8e92 !important;
}
.filtered-facility-list .card.facility-card .facility-subtitle {
  font-size: 16px;
  width: 50%;
}

@media only screen and (max-width: 425px) {
  .facility-create-button {
    position: fixed !important;
    bottom: 0;
    left: 10px;
    right: 10px;
    background: white;
    padding-top: 10px !important;
  }

  .facility-create-button .MuiButton-label {
    font-size: 15px !important;
  }

  .success-facility-btn {
    position: fixed !important;
    bottom: 10px;
    left: 10px;
    right: 10px;
    background: white;
    padding-top: 10px !important;
  }
}
