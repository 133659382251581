// -------------colors ------------//
// $DARK: #394053;
$DARK: #212121;
// $DARK_SHADE1: #262a32;
$BLACK: #000;
$WHITE: #ffffff;
$WHITE_SHADE1: #f8f9fe;
$BLUE: #2c6fed;
$GRAY_SHADE8: #394053;
$LIGHT_GRAY: #eaeaea4a;
$LIGHT_GRAY_SHADE1: #c2c6d070;
$LIGHT_GRAY_SHADE2: #b6b8bb;
$LIGHT_GRAY_SHADE3: #979797;
$LIGHT_GRAY_SHADE4: #dee0e4;
$LIGHT_GRAY_SHADE5: #f6f6f6;
$LIGHT_GRAY_SHADE6: #f0f2f7;
$LIGHT_GRAY_SHADE7: #a3a7ad;
$GREEN: #20a258;
$GREEN_SHADE1: #eefbf1;
$ORANGE: #fc8434;
$ORANGE_SHADE1: #fff6ee;
$ORANGE_SHADE2: #df9e6d;
$WARNING: #fc3e34;
$WARNING_SHADE1: #c8342f;
$DANGER_SHADE1: #ffeded;
$DARK_SHADE1: #181D25;
$GRAY_SHADE2: #8C8E92;