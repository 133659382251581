@import "../constants/base/colors.scss";
@import "../constants/base/global.scss";

.incident-Listing-wrapper {
  #demo-simple-select-outlined {
    background: white;
  }

  .video-img .img-layer,
  .card .card-img-row .card-img,
  .card .card-img-row .incident-dialog-video {
    width: 120px;
    height: 120px;
  }

  .card.MuiCard-root {
    border-radius: 8px;
  }

  .title-span,
  .card.MuiCard-root h4 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    text-transform: capitalize;
  }

  .card.incident-detail-card .card-rows h4 {
    padding-left: 0 !important;
  }

  .customButton .MuiButton-contained {
    border-radius: 25px;
  }

  .incident-status-blue {
    color: #2B6FED;
    background-color: #e3edff;
  }

  .incident-details-unresolved {
    color: #F21717;
    background-color: #ffd5d5 !important;
    border-radius: 8px;

    #demo-simple-select-outlined {
      color: #F21717 !important;
      background-color: #ffd5d5 !important;
      font-weight: 600;
    }
  }

  .incident-details-resolved {
    color: #1BCD47;
    background-color: #d2ffdd !important;
    border-radius: 8px;

    #demo-simple-select-outlined {
      color: #1BCD47 !important;
      background-color: #d2ffdd !important;
      font-weight: 600;
    }
  }

  .incident-details-ongoing {
    color: #2B6FED;
    background-color: #e3edff !important;
    border-radius: 8px;

    #demo-simple-select-outlined {
      color: #2B6FED !important;
      background-color: #e3edff !important;
      font-weight: 600;
    }
  }

  .incident-details-pending {
    color: #fc8434;
    background-color: #ffe4d2 !important;
    border-radius: 8px;

    #demo-simple-select-outlined {
      color: #fc8434 !important;
      background-color: #ffe4d2 !important;
      font-weight: 600;
    }
  }

  .incident-details-pendingConfirmation {
    color: #F21717 !important;
    background-color: #ffdcdc !important;
    border-radius: 8px;

    #demo-simple-select-outlined {
      color: #F21717 !important;
      background-color: #ffdcdc !important;
      font-weight: 600;
    }
  }

  .incident-status-blue:hover {
    color: white;
    background-color: #2B6FED;
  }

  .MuiContainer-maxWidthLg {
    max-width: 1400px;
  }

  & .sorting-header {
    margin-top: 15px;
    display: flex;
    align-items: center;
    width: 100%;

    &.classified-sorting-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & .left-block {
        display: flex;
      }

      & .classified-search-iput {
        &::placeholder {
          color: $DARK;
          font-size: 18px;
          font-weight: 500;
        }

        & .MuiOutlinedInput-input {
          padding: 10px 32px 10px 0;

          &::placeholder {
            color: $DARK;
            font-size: 18px;
            font-weight: 500;
          }
        }

        & .MuiSvgIcon-root {
          color: #515151;
        }
      }
    }

    & .MuiOutlinedInput-input {
      padding: 10px 32px 10px 14px;
    }

    & .customSelect {
      margin-right: 10px;
      margin-bottom: 0;

      & .MuiOutlinedInput-root {
        padding-left: 0;
        background: white;
      }
    }

    & .customButton {
      & .MuiButton-root {
        padding: 5px 15px;
      }
    }
  }

  & .incident-button-row {
    display: flex;
    justify-content: flex-end;
    padding-top: 80px;
    //padding-right: 50px;

    &.customButton {
      & .MuiButton-root {
        padding: 10px 20px;
        width: auto;
        margin-left: 10px;
      }

      & .MuiButton-outlined {
        border: 1px solid $BLUE;

        & .MuiButton-label {
          color: $BLUE;
        }

        &:hover {
          & .MuiButton-label {
            color: $WHITE;
          }
        }
      }
    }

    & .user-btn-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid $BLUE;
      border-radius: 8px;
      padding: 5px 20px;
      min-width: 250px;

      & .change-btn {
        padding: 10px 0;
      }

      & h6,
      & a {
        font-size: 14px;
        text-transform: uppercase;
      }

      & a {
        color: $BLUE;
        font-weight: bold;
      }
    }
  }
}