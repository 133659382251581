@import '../constants/base/colors.scss';
@import '../constants/base/global.scss';

.customSelect {
  & .MuiFormControl-root {
    width: 100%;
  }
  & .frmLeftIcons {
    z-index: 9;
  }
  & fieldset {
    top: 0;
    & legend {
      display: none;
    }
    outline: 0;
  }
  & .MuiOutlinedInput-input {
    padding-left: 0;
    padding-right: 0;
  }
  & .MuiSelect-select {
    &:focus {
      background-color: #f9f9f9;
    }
  }
  & .MuiOutlinedInput-root {
    padding-left: 50px;
    color: $LIGHT_GRAY_SHADE3;
    border-radius: $FORM_BORDER_RADIUS;
    border: 1px solid #f4f4f4;
    background-color: #f9f9f9;
    outline: 0;
    &:hover {
      & fieldset {
        border-color: #e9dede;
      }
    }
    & .MuiOutlinedInput-notchedOutline {
      border-color: #e9dede;
      border-width: 1px;
    }
    &.Mui-focused {
      & .MuiOutlinedInput-notchedOutline {
        border-color: #e9dede;
        border-width: 1px;
      }
    }
  }
}
.desktop-ui {
  @include mq(tablet-portrait-large-up) {
    & .customSelect {
      & .MuiOutlinedInput-root {
        border-radius: 25px;
      }
    }
  }
}

.priceSelect {
  & .MuiFormControl-root {
    width: 100%;
  }
  & .frmLeftIcons {
    z-index: 9;
  }
  & fieldset {
    top: 0;
    & legend {
      display: none;
    }
    outline: 0;
  }
  & .MuiOutlinedInput-input {
    padding-left: 0;
    padding-right: 0;
  }
  & .MuiSelect-select {
    &:focus {
      background-color: #f9f9f9;
    }
  }
  & .MuiOutlinedInput-root {
    padding-left: 50px;
    color: $LIGHT_GRAY_SHADE3;
    border-radius: $FORM_BORDER_RADIUS;
    background-color: #f9f9f9;
    outline: 0;
    &:hover {
      & fieldset {
        border-color: #e9dede;
      }
    }
    & .MuiOutlinedInput-notchedOutline {
      border-color: #e9dede;
      border-width: 1px;
    }
    &.Mui-focused {
      & .MuiOutlinedInput-notchedOutline {
        border-color: #e9dede;
        border-width: 1px;
      }
    }
  }
}

.multi_select__form_box {
  .dropdown-container:focus-within {
    box-shadow: none;
  }

  .dropdown-container {
    border: 1px solid #f0f0f0 !important;
    border-radius: 8px !important;
    background-color: #f9f9f9 !important;
    width: 100%;
    font-family: $Gotham_Medium !important;
    outline: none !important;

    .clear-selected-button {
      display: none !important;
    }

    .search {
      border: 1px solid #e9e9e9;
      width: unset;
      border-radius: 8px;
      margin: 15px 15px 5px;

      input {
        border-radius: 8px;
      }
    }

    .options {
      margin: 0 15px;

      .select-item {
        background-color: white !important;
      }

      li {
        border-top: 1px solid #e9e9e9;
      }

      li.no-options {
        border-top: 0;
      }
    }

    .dropdown-heading {
      padding: 16px 12px !important;
      height: unset;
      color: black;
    }
    .dropdown-content {
      position: fixed;
      width: 550px;
      top: unset;

      .panel-content {
        box-shadow: 4px 0px 14px #e9e9e9 !important;
      }

      .item-renderer {
        align-items: center;

        input[type='checkbox'] {
          width: 18px;
          height: 18px;
          accent-color: #ffa500;
          border: 1px solid #ffa500;
          margin-right: 10px;
        }

        span {
          color: black !important;
        }
      }
    }
  }
}

.select_icon__form_box {
  width: 100%;

  .select__select_box {
    position: relative;
  }

  img {
    position: absolute;
    z-index: 999;
    top: 16px;
    left: 10px;
  }

  label {
    margin-bottom: 8px;
    color: black;
    font-size: 14px;
  }

  .select__input {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    background: #f9f9f9;
    width: 100%;
    padding-left: 35px;

    .select__select_icon {
      margin-right: 10px;
      color: darkgray;
      font-weight: 100;
    }
  }

  .Mui-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
    background-color: #f0f0f0 !important;
  }
}

.select__form_box {
  .select__input {
    padding-left: 0px;
  }
}

.select_icon__form_box.arabic_select_box {
  .MuiSelect-select.MuiSelect-select {
    padding-right: 14px;
  }

  .select__select_icon {
    margin-left: 10px;
  }

  .MuiSelect-iconFilled {
    left: 10px !important;
    right: unset;
  }
}

.real_estate_phone .react-tel-input {
  top: 10px !important;
}

.input_icon__form_box {
  position: relative;
  width: 100%;

  label {
    margin-bottom: 8px;
    color: black;
    font-size: 14px;
  }

  .input_box {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    background: #f9f9f9;
    padding: 10px 12px;
    outline: none;
    font-size: 16px;
  }

  .icon_date {
    padding-left: 48px;
    width: 86% !important;

    .Mui-error.MuiFormHelperText-root {
      display: none;
    }
  }

  .icon_out_date {
    width: 94% !important;
    .Mui-error.MuiFormHelperText-root {
      display: none;
    }
  }

  .textarea-box {
    width: 100%;
    resize: none;
  }

  .input_box::before,
  .input_box::after,
  .MuiInput-root::before,
  .MuiInput-root::after {
    border: 0;
    outline: none !important;
    transition: none !important;
    content: none !important;
  }

  .country > div {
    padding: 0 !important;
  }

  input::placeholder {
    color: #adadad !important;
    opacity: 1; /* Firefox */
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #adadad !important;
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #adadad !important;
  }

  .Mui-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
    background-color: #f0f0f0 !important;
  }
}

.input_icon__form_box.input_box__search_filter {
  width: 275px;

  .input_box {
    background: white;
    border: 1px solid #f0f0f0;
    padding: 4px 12px;
  }

  img {
    width: 14px;
    height: 14px;
  }

  .Mui-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
    background-color: #f0f0f0 !important;
  }
}

.form__phone_number {
  position: relative;

  .react-tel-input {
    position: absolute;
    left: 0;
    top: 33px;
    z-index: 9;
    width: fit-content;
    border-right: 1px solid #f0f0f0;

    .country-list {
      position: fixed;
    }

    .selected-flag {
      background: transparent !important;
    }
  }

  .input_icon__form_box .input_box {
    padding-left: 120px;
  }
}

.dialog__button_group {
  gap: 10px;
  padding: 10px 24px 20px !important;
  flex-direction: row !important;

  button {
    height: 42px;
    font-weight: 600;
    border-radius: 8px;
    width: 175px;
  }

  .add-button,
  .add-button:hover {
    background-color: #2b6fed;
    border: 1px solid #2b6fed;
    color: white;
  }

  .cancel-button {
    border: 1px solid #2b6fed;
    background-color: white;
    color: #2b6fed;
  }
}

.filter-head-box {
  .select-year.input__filter_input,
  .select-year.input__filter_input:hover {
    padding: 5px 12px;

    input {
      color: black;
    }

    input::placeholder,
    input:-ms-input-placeholder,
    input::-ms-input-placeholder,
    input:-moz-placeholder,
    input::-webkit-input-placeholder,
    input::-moz-placeholder {
      color: darkgray !important;
      opacity: 1 !important;
    }
  }

  .date__filter_input {
    .MuiFormHelperText-root.Mui-error {
      display: none;
    }

    .MuiInputBase-root::after,
    .MuiInputBase-root::before {
      display: none;
    }
  }
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
}

.arabic_search_icon {
  margin-left: 10px;
}
