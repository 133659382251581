@import '../constants/base/colors.scss';
@import '../constants/base/global.scss';

.customButton {
  & .MuiButton-contained {
    width: 100%;
    background-color: $BLUE;
    color: $WHITE;
    font-size: 15px;
    &.contain {
      padding: 5px 15px;
      box-shadow: none;
      & span {
        font-size: 12px;
        font-weight: 900;
        white-space: nowrap;
        text-transform: capitalize;
      }
    }
    &.success {
      background-color: $GREEN_SHADE1;
      & span {
        color: $GREEN;
      }
      &:hover {
        background-color: $GREEN;
        & span {
          color: $GREEN_SHADE1;
        }
      }
    }
    &.warning {
      background-color: $ORANGE_SHADE1;
      & span {
        color: $ORANGE;
      }
      &:hover {
        background-color: $ORANGE;
        & span {
          color: $GREEN_SHADE1;
        }
      }
    }
    &.danger {
      background-color: $DANGER_SHADE1;
      & span {
        color: $WARNING;
      }
      &:hover {
        background-color: $WARNING_SHADE1 !important;
        & span {
          color: $DANGER_SHADE1;
        }
      }
    }
    &.blue {
      background-color: #ebf4ff;
      & span {
        color: #2686f7;
      }
      &:hover {
        background-color: #2686f7;
        & span {
          color: $GREEN_SHADE1;
        }
      }
    }
    @include mq(tablet-landscape-up) {
      font-size: 14px;
    }
    &:hover,
    &:active {
      background-color: $BLUE;
      color: $WHITE;
    }
  }
  & .MuiButton-outlined {
    border: 1px solid $LIGHT_GRAY_SHADE1;
    & span {
      color: $DARK;
    }
    &:hover {
      background-color: $BLUE;
      & span {
        color: $WHITE;
      }
    }
  }
  & .outline-danger {
    & .MuiButton-outlined {
      border: 1px solid $WARNING !important;
      & span {
        color: $WARNING !important;
      }
      &:hover {
        background-color: $WARNING !important;
        & span {
          color: $WHITE !important;
        }
      }
    }
  }
  & .MuiButton-root {
    font-weight: 600;
    border-radius: 30px;
    padding: 15px;
    text-transform: uppercase;
  }
  & .MuiButton-text {
    color: #8d8d8d;
    @include mq(tablet-landscape-up) {
      font-size: 14px;
    }
  }
}
.desktop-ui {
  @include mq(tablet-portrait-medium-up) {
    & .customButton {
      & .MuiButton-root {
        border-radius: $FORM_BORDER_RADIUS;
      }
    }
  }
}

.common_btn.MuiButton-root,
.common_btn.MuiButton-root:hover {
  border-radius: 8px;
  min-width: auto;
  font-size: 14px;
  padding: 8px 20px;
  box-shadow: none;
  font-weight: 600;
  min-height: auto;
}

.common_btn.blue_btn,
.common_btn.blue_btn:hover {
  background-color: #2b6fed;
  border: 1px solid #2b6fed;
  color: white;
}

.common_btn.white_btn,
.common_btn.white_btn:hover {
  background-color: #dbdee5 !important;
  border: 1px solid #dbdee5;
  color: black !important;
}

.common_btn.blue_btn.variant,
.common_btn.blue_btn.variant:hover {
  border: 1px solid #2b6fed;
  color: #2b6fed;
  background-color: transparent;
}

.common_btn.orange_btn,
.common_btn.orange_btn:hover {
  background-color: #fe8335;
  border: 1px solid #fe8335;
  color: white;
}

.common_btn.orange_btn.Mui-disabled {
  background-color: #ffaf7d;
  border: 1px solid #ffaf7d;
  color: white;
}

.common_btn.orange_btn.variant,
.common_btn.orange_btn.variant:hover {
  border: 1px solid #fe8335;
  color: #fe8335;
  background-color: transparent;
}
