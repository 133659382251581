@import "../constants/base/colors.scss";
@import "../constants/base/global.scss";

@media only screen and (max-width: 425px) {
  .incident-list-owner.incident-content-wrapper {
    margin-bottom: 80px !important;
  }

  .add-new-incident-btn {
    padding: 10px 0;
    position: fixed !important;
    width: 94%;
    background-color: #F7F9FE;
  }
}

.incident-wrapper {
  margin: 0 auto;

  .incident-details-ongoing {
    color: #2B6FED;
    background-color: #e3edff;
    box-shadow: none;
    border-radius: 30px !important;
    padding: 5px 15px !important;
    white-space: nowrap !important;
    font-size: 12px !important;
    text-transform: capitalize;
  }

  @include mq(tablet-portrait-medium-up) {
    overflow: hidden;
  }

  & .content-block {
    padding: 0;
  }

  & .content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px;
    border-bottom: 2px solid #f2efef;
    background-color: $WHITE;

    @include mq(tablet-portrait-medium-up) {
      padding: 25px 25px 20px 25px;
    }

    & .blocks {
      display: flex;
      align-items: center;

      & .MuiSvgIcon-root {
        height: 1.5em;
        width: 1.5em;
      }

      & .MuiButton-text {
        padding: 0;
        min-width: auto;
      }
    }

    & h4 {
      padding-left: 10px;
    }

    & .icons {
      padding: 0 5px;
    }
  }

  .content-block-wrapper {
    padding: 0 10px;

    &.neighbor-content-block-wrapper {
      padding: 0;
    }

    @include mq(tablet-portrait-medium-up) {
      padding: 0 25px;
    }

    &.facility-block-wrapper {
      @include scrollbars();
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100%;
      background-color: #f8f9fe;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include mq(tablet-portrait-medium-up) {
        width: 450px;
        margin: 10px auto;
      }

      & .customButton {
        position: sticky;
        bottom: 0;
        z-index: 9;

        &.preview-submit {
          position: unset;
          margin-top: 20px;
        }

        & .MuiButton-label {
          white-space: nowrap;
          font-size: 12px;
          font-weight: 700;
        }
      }

      & .card-listing-row {
        & .h5-title {
          font-weight: bold;
        }
      }
    }

    &.common-incident-block {
      &.create-reservation-wrapper {
        & .reservationDec {
          background-color: #F9F9F9;
          padding: 20px;
          border: 1px solid #F0F0F0;
          border-radius: 10px;
          margin-bottom: 20px;

          & p {
            color: #181D25;
          }

          & span {
            color: $ORANGE;
          }
        }
      }

      &.classifiedtype-block {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      @include scrollbars();
      overflow-y: scroll;
      margin: 0;
      position: relative;

      @include mq(tablet-portrait-medium-up) {
        width: 450px;
        margin: 10px auto;
      }

      & .customButton {
        position: sticky;
        bottom: 0;
        z-index: 9;

        &.preview-submit {
          position: unset;
          margin-top: 20px;
        }

        & .MuiButton-label {
          white-space: nowrap;
          font-size: 12px;
          font-weight: 700;
        }
      }

      & .add-incident,
      & .ticket-conversion {
        background-color: white;
        padding: 10px;
        border-top: 2px solid lightgray;
        margin-top: 10px;

        @include mq(tablet-portrait-medium-up) {
          margin-top: 0;
        }
      }
    }

    & .incident-content-wrapper {
      margin-top: 10px;

      // height: 100%;
      & .no-classification-wrapper {
        min-height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include mq(tablet-portrait-medium-up) {
          min-height: 600px;
        }

        & .no-classification {
          display: flex;
          flex-direction: column;
          align-items: center;
          // margin: 100px 0;
          text-align: center;

          & h1 {
            font-weight: bold;
          }
        }
      }

      & .classifiedCardRow {
        position: relative;

        & .menu-btn {
          position: absolute;
          right: 0;
          padding: 2px;
          top: 50px;

          &.MuiButton-root {
            &:hover {
              text-decoration: none;
              background-color: transparent;
            }
          }
        }
      }

      & .classified-header {
        display: flex;

        & .customButton {
          margin-right: 15px;
          padding-top: 0;

          & .MuiButton-label {
            text-transform: capitalize;
          }
        }

        & .MuiButton-root {
          padding: 10px 15px;
        }

        & .btn-gray {
          & .MuiButton-contained {
            width: 100%;
            background-color: $LIGHT_GRAY_SHADE6;
            color: $LIGHT_GRAY_SHADE7;
            font-size: 15px;
            box-shadow: none;

            & .MuiButton-label {
              font-size: 14px;
              color: $LIGHT_GRAY_SHADE7;
              text-transform: capitalize;
            }
          }
        }
      }

      @include mq(tablet-portrait-medium-up) {
        margin-top: 25px;
      }

      & .incident-rows {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        align-items: center;

        & .customButton {
          padding: 0;
          margin: 0;
        }
      }

      // & .incident-sub-row {
      //   display: flex;
      //   flex-direction: row;
      //   justify-content: space-between;
      //   margin: 20px 0;
      // }
      @include mq(tablet-landscape-up) {
        // max-height: 500px;
      }
    }

    & .incident-preview-title {
      padding-top: 10px;
    }
  }

  & .customButton {
    padding-bottom: 20px;
  }
}