.owner-suggestion .formGroup .MuiInputBase-root {
  border-color: #f0f0f0 !important;
}

@media only screen and (max-width: 425px) {
  .new-suggestion-create-btn,
  .success-suggestion-btn,
  .add-incident-list-btn {
    position: fixed !important;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: 0 !important;
    padding: 12px !important;
  }
  .grayColor {
    background-color: rgb(248, 249, 254) !important;
  }
}
