.profile_img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.first_icon {
  border-right: 2px solid #d6dbe4;
  padding-right: 0.75rem;
}

.second_icon {
  border-right: 2px solid #d6dbe4;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.third_icon {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.label {
  font-weight: bold;
}

.hobbies {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;
  color: #fc8434;
  margin-left: 0.5rem;
  background-color: #fff6ee;
  border-radius: 25px;
  padding: 0.5rem;
}

.active {
  color: blue;
  font-weight: bold !important;
  // border: 5px solid gray;

  & .MuiTypography-body1 {
    font-weight: 600;
  }
}

.unactive {
  color: gray;
  font-weight: bold !important;

  & .MuiTypography-body1 {
    font-weight: 600;
  }
}

.MuiTypography-body1 {
  font-weight: bold;
}

h4.MuiTypography-body1 {
  font-size: 14px;
  margin: 10px 0;
  font-weight: 600;
  color: #181d25;
}

.card-top-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.social-raw .blocks {
  border-right: 2px solid #d7dbe4;
  padding: 0 10px;
}

.social-raw .blocks_ar {
  border-left: 2px solid #d7dbe4;
  padding: 0 10px;
}
.social-raw {
  position: relative;
}

.social-raw {
  padding: 8px 2px;
}

.social-raw {
  display: flex;
  background-color: #f6f6f6;
  padding: 12px;
  border-radius: 20px;
  // margin-top: 10px;
}

.social-raw {
  position: relative;
  //&::before {
  //  content: " ";
  //  position: absolute;
  //  top: 20px;
  //  left: -100%;
  //  border-bottom: 1px solid #f6f6f6;
  //  width: 100%;
  //}
  //
  //&::after {
  //  content: " ";
  //  position: absolute;
  //  top: 20px;
  //  right: -100%;
  //  border-bottom: 1px solid #f6f6f6;
  //  width: 100%;
  //}
}

.white-box {
  /* padding: 1rem; */
  background: white;
  border-radius: 8px;
  display: flex;
}

// .left-side{
//   border-right: 1px solid #1A181D25;
//     max-width: 200px;
//     padding: 1rem;
// }
.icons {
  // display: flex;
  // gap: 1.25rem;
  // margin-top: 1rem;
}

.right-side {
  padding: 1rem;
  width: 100%;

  .heading {
    color: #181d2557;
  }

  .content {
    color: #181d25;
    padding: 10px 20px 10px 0px;
  }
}

.social-icon {
  .icon-1 {
    background: #f6ece6;
    padding: 0.5rem;
    border-radius: 50%;
  }
}

.btnMy .MuiButton-label {
  color: white !important;
  font: caption !important;
}

.MuiNativeSelect-select.MuiNativeSelect-select {
  // font: caption !important;
}

.MbtnMy .MuiButton-label {
  color: #2773df !important;
}

.MuiButtonBase-root-2 .MuiButton-label {
  color: white !important;
}

// ul.szh-menu.szh-menu--state-open.szh-menu--dir-bottom{
//   top:0 !important;
//   left:-214px !important;
// }

//input[type="date"]::-webkit-calendar-picker-indicator {
//  position: absolute;
//  left: 5%;
//  color: rgba(0, 0, 0, 0);
//  opacity: 1;
//  display: block;
//  background-repeat:  no-repeat;
//  width: 25px;
//  height: 25px;
//  border-width: thin
//}
//
//input::-webkit-datetime-edit-fields-wrapper {
//  position: relative;
//  left: 8%;
//}

//input::-webkit-datetime-edit {
//  position: relative;
//  left: 8%;
//}

.my-date{
  width: 160px !important;
  input{
    padding-left: 2rem;
  }
}
.my-date::after{
  border: none !important;
  border-bottom: none !important;

}
.my-date::before{
  border: none !important;
  border-bottom: none !important;

}